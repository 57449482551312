.container {
  height: 302px;
  width: 100%;
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: 302px;
  gap: 1.5rem;
  @media (max-width: 700px) {
    grid-template-columns: auto;
    grid-template-rows: 302px 302px;
  }
}

.button {
  height: 100%;
  min-width: 100%;
  max-width: calc(10vw - 24px - 24px);

  background: linear-gradient(180deg, var(--primary-500) 0%, var(--primary-300) 100%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 3px;

  outline: none;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px rgba(12, 43, 66, 0.06), 0px 0px 1px rgba(17, 60, 92, 0.06);

  cursor: pointer;

  * {
    color: var(--white);
    fill: var(--white);
  }
  svg {
    height: 40px !important;
    width: 40px !important;
  }
  span {
    font-family: 'Roboto', sans-serif;
    margin-top: 15px;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
  }
  svg,
  span {
    filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.8));
    transform: translate(0%, 0%);
    transition: 40ms ease-in-out;
    will-change: transform, filter;
  }
  &:hover {
    svg,
    span {
      transition: 100ms ease-in-out;
      filter: drop-shadow(3px 3px 3px rgba(255, 255, 255, 0.5));
      transform: translate(-3px, -3px);
    }
    &:active {
      svg,
      span {
        filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.8));
        transform: translate(0%, 0%);
        transition: 40ms ease-in-out;
      }
    }
  }
}

.infoContainer {
  width: 100%;
  background: var(--white);

  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px rgba(12, 43, 66, 0.06), 0px 0px 1px rgba(17, 60, 92, 0.06);
  border-radius: 3px;
  padding: 1.5rem;

  display: grid;
  grid-template-rows: 2.5rem 212px;
  grid-template-columns: 359px auto;
  gap: 0 1.5rem;
  .label {
    margin: 0;
    grid-column: 1/-1;
    padding-bottom: 1rem;
  }

  .youtubeVideo {
    height: 100%;
    width: 100%;
    border: 1px solid var(--yellow-100);
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    & > div {
      height: 100%;
      width: 100%;
      overflow: hidden;
      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }
  .yellowBox {
    position: relative;
    padding: 1.5rem;
    background: var(--yellow-100);
    border: 1px solid var(--yellow-100);
    box-sizing: border-box;
    border-radius: 8px;
    overflow: hidden;
    * {
      margin: 0;
    }
    span {
      display: block;
      margin-top: 0.5rem;
      font-size: 0.875rem;
      color: var(--gray-600);
    }
    svg {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .linkList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    a {
      text-decoration: none;
      margin-bottom: 0.5rem;
      line-height: 21px;
      letter-spacing: 0.02em;
      svg {
        margin-right: 10px;
        height: 24px;
        width: 24px;
      }
    }
  }
}
