.InputText {
  display: flex;
  flex-direction: column;
  color: var(--gray-800);
  gap: 8px;

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__inputContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__hint {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--gray-600);
  }

  &__error {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--danger);
  }
}
