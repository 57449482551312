.title {
  margin: 32px 0 30px 0 !important;
}
.paper {
  padding: 24px;
}
.unverifiedContainier {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .phoneInput {
    margin-bottom: 24px;
    max-width: 355px;

    & > div:last-child {
      position: relative;
      display: block;
      width: 100%;
    }
  }
}

.confirmNumberWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .verifiedPhone {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .success {
    margin-left: 26px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    & * {
      color: var(--success) !important;
    }
    & svg {
      margin-right: 8px;
    }
  }
  .testButton {
    grid-area: testButton;
  }
}

.testResult {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.questionsContainer {
  width: 100%;
  & > *:first-child {
    height: 1rem;
    margin-bottom: 22px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.questions {
  & > * {
    margin: 2px 0 2px 0 !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.buttonsContainer {
  margin-top: 32px;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'back continue .';
  gap: 8px;
  & > * {
  }
  & > *:first-child {
    grid-area: back;
    justify-self: end;
    white-space: nowrap;
  }
  & > *:last-child {
    grid-area: continue;
    justify-self: center;
  }
}

.paddingCustom {
  padding: 14px 0 14px 8px;
  & svg {
    cursor: pointer;
  }
}
.confirmNumberBtnVerify {
  max-width: 100%;
  flex-basis: 100%;
  justify-content: center;
  margin-bottom: 24px !important;
}
.colorB60 {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--gray-600);
  &.span {
    font-size: 0.75rem;
  }
}
.errorTimer {
  margin-top: 20px !important;
  text-align: center;
  color: var(--gray-600) !important;
  font-weight: 400 !important;
}

.disabledText {
  color: var(--gray-300);
}

.spinner {
  position: relative;
  height: 40px;
  width: 40px;
}

.warningLink {
  a {
    color: var(--yellow-600);
  }
}

.captcha {
  & > div {
    bottom: 2px !important;
  }
  transform: none !important;
  * {
    transform: none !important;
  }
}

.digits {
  text-align: center;
  margin-bottom: 24px;
  input {
    border-radius: 8px;
  }

  div:last-child {
    position: relative;
    display: block;
    width: 100%;
  }
}
