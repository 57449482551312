$emojis: brain, smiling-face, bust-in-silhouette, briefcase, cross-mark, eyes, grinning-face;

@each $emoji in $emojis {
  .just-emoji-#{$emoji} {
    display: inline-block;
    width: 0.8rem;
    height: 1rem;
    background: url('/img/just-emoji/' + $emoji + '.png') no-repeat top;
    background-size: contain;
    vertical-align: middle;
  }
}
