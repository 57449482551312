@mixin gap {
  margin-bottom: 8px !important;
}

.container {
  color: var(--gray-800);
  margin: 0 auto;
  max-width: 1168px;
  padding: 0.5rem 1.5rem 1.5rem;
}

.title {
  margin-bottom: 1.25rem;
}

.businessAndOperators {
  .channelTypeLabel:last-of-type:not(:only-of-type) {
    padding-top: 16px;
  }
}

.createdChannels {
  @include gap;
  margin-bottom: 2rem !important;
}

.addNewChannelLabel {
  @include gap;
  margin-bottom: 1.25rem !important;
}

.channelTypeLabel {
  margin: 0 !important;
  margin-bottom: 16px !important;

  font-weight: 700;
}

.noChannelHeadline {
  margin: 0;
}

.deleteChannelWarning {
  margin-top: 24px;
  & p {
    color: var(--yellow-600) !important;
  }
}

.voiceCard {
  & .voiceCard {
    margin-bottom: 4px;
    position: relative;
  }
}

.operators {
  margin-bottom: 2rem;
}

.subtitle {
  grid-area: operators;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 20px;
  }
}

.card {
  width: 100%;
  height: 48px;
  margin: 8px 0;
  padding: 0 0.625rem 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  position: relative;

  background-color: var(--white);
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;

  cursor: pointer;

  .beta {
    position: absolute;
    height: 16px;
    right: 1rem;

    padding: 0 4px;

    color: var(--white);
    font-weight: 600;
    font-size: 8px !important;
    background: var(--warning);
    border-radius: 9px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  * {
    font-size: 0.875rem;
    color: var(--gray-800);
    font-weight: 700;
    margin: 0;
  }
  & > *:first-child {
    margin-right: 10px;
  }
  & > *:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &.disabledCard {
    cursor: default;
    background-color: var(--gray-100);

    svg,
    & .disabledText {
      pointer-events: none;
      color: var(--gray-500);
    }
  }
}

.noIncomeChannels {
  width: 100%;
  height: 62px;
  border: 1px solid var(--gray-200);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  span {
    color: var(--gray-600);
    font-weight: 400;
    font-size: 0.875rem;
  }
}
.noOperatorChannels {
  @include gap;
  width: 100%;
  height: 62px;
  border: 1px solid var(--gray-200);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  span {
    color: var(--gray-600);
    font-weight: 400;
    font-size: 0.875rem;
  }
}

.textCenter {
  text-align: center;
}
.helpLink,
.warningOperatorsText {
  margin-top: 0.25rem;
  font-size: 0.75rem;
}

.warningOperatorsText {
  color: var(--gray-600);
}

.channelsWrapper {
  @media (min-width: 700px) {
    display: flex;

    & > div {
      width: calc(100% / 3);

      margin-left: 1.5rem;
    }

    & > div:first-child {
      margin-left: 0;
    }
  }
}

.inWizardChannelsWrapper {
  & .incomingWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    row-gap: 0;
  }
}

.paragraphMargin {
  margin-bottom: 16px;
}
