.justui_input-with-dropdown {
  .justui_dropdown {
    width: 100%;

    &.fixedDropdown {
      z-index: 600;
      .justui_dropdown-menu {
        position: relative !important;
      }
    }
    &.show {
      .justui_dropdown-menu.show {
        width: 100%;
        opacity: 1 !important;
        pointer-events: all !important;

        button:focus {
          outline: none;
        }
      }
    }
  }
}
