.SkillsSelectContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1.5rem;
  background-color: var(--white);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 12px;
  color: var(--gray-800);
  width: 100%;
  margin-bottom: 100px;

  &_skillContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
}
