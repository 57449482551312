.PseudoWidget {
  display: block;
  position: sticky;
  top: 20px;
  height: 70vh;

  [class~='justwidget--headline-height-button-container'] {
    display: none !important;
  }

  [class~='justwidget'],
  &_Body {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    bottom: unset !important;
    right: unset !important;

    overflow: hidden;
    max-width: 288px !important;

    [class~='justwidget--message'] {
      width: 258px !important;
    }
    [class~='justwidget--system-message'] {
      padding: 12px 16px !important;
    }

    [class='justwidget--buttons-inline'] {
      max-width: 288px !important;
      [class='justwidget--buttons'] {
        gap: 8px;
        margin-bottom: 8px;
      }
      [class='justwidget--button'] {
        min-width: auto;
        margin: 0 !important;
      }
    }
  }
  &_Body {
    [class~='justwidget--headline'] {
      h2 {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
    [class~='justwidget--messages'] {
      background: var(--blue-50);
    }
  }
  [class='justwidget--message_content active'] {
    background-color: var(--blue-100) !important;
    border: 1px solid var(--blue-200) !important;
  }

  &_deployButtonContainer {
    display: flex;
    width: 100%;
    padding: 10px 16px !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);

    &_deployButton {
      width: 100%;
      padding: 0.375rem 0.775rem !important;
      margin-top: 16px;
    }
  }
}
