.ManagerNotify {
  display: grid;
  grid-row-gap: 12px;
  grid-column-gap: 16px;
  grid-template-columns: auto 1fr;
  align-items: flex-start;

  & :global(.custom-control-label) {
    color: var(--gray-800);
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &__input {
    min-width: 200px;
    max-width: 250px;
    width: 250px;
  }

  &__email {
    min-width: 200px;
    max-width: 300px;
    width: 300px;
  }

  & :global(.custom-control-inline) {
    margin-right: 4px;
  }

  & .checkBoxWrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 4px;

    & svg {
      display: flex;
    }
  }
}
