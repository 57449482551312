.templateLabel {
  margin-bottom: 28px;
}

.templatesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 1.5rem;
  width: 100%;
}

.templateCard {
  cursor: pointer;
  box-shadow: none;
  border: solid 1px var(--gray-200);
  min-height: 297px;
  position: relative;
  left: 0;
  top: 0;
  will-change: left, top, filter;
  transition: 100ms ease-in-out;
  contain: paint;
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.3));

  &:hover {
    & .blocked {
      opacity: 1 !important;
    }

    left: -2px;
    top: -2px;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
    &:active {
      left: 0;
      top: 0;
      cursor: default;
      filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.3));
    }
  }

  .cardHeadline {
    margin-top: 0;
  }

  .media {
    height: 140px;
  }

  .newLabel {
    width: 29px;
    height: 16px;
    position: absolute;
    top: 132px;
    left: 20px;
    background: var(--warning);
    border-radius: 9px;
    font-size: 8px;
    line-height: 16px;
    color: var(--white);
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .blocked {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    bottom: 0;
    right: 0;
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: 100ms ease-in-out;
    z-index: 10;
    span {
      display: block;
      margin: 1rem 0;
    }
  }
}
