.BotSettings {
  position: relative;
  display: flex;
  gap: 20px;

  &__emojiFix {
    & :global(.just-select-item::first-letter) {
      font-family: monospace;
    }
    & :global(input) {
      font-family: Roboto;
    }
  }

  .input {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
}
