@import '../../../../scss/mixins/scrollbar';

.tagsFilter {
  display: block;
  text-align: right;
  position: relative;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  font-weight: 400;
  padding: 9px 8px;
  color: var(--gray-900);
  cursor: pointer;

  > span {
    margin-left: 8px;
  }

  .selectedValues {
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    padding: 0 4px;
    background: var(--primary);
    border-radius: 3px;
    color: var(--white);
    margin-left: 8px;
  }

  &.show {
    background: var(--gray-300);
    border-radius: 3px;

    + .tagsFilterContainer {
      display: block;
    }
  }
}

.tagsFilterContainer {
  position: absolute;
  width: 320px;
  height: 376px;
  top: 100%;
  right: 0;
  padding: 25px 17px;
  background: var(--white);
  z-index: 2;
  display: none;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 6px 12px rgba(12, 43, 66, 0.16), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 3px;
  .just-select-backdrop {
    display: none !important;
  }

  .just-select-list {
    box-shadow: none;
  }
  .just-select-selected-item {
    background: var(--yellow-100);
    border-color: var(--yellow-100);
    color: var(--yellow-700);
    .just-select-selected-item-delete {
      fill: var(--yellow-400);
    }
  }
  .just-select-wrapper {
    .just-select-container {
      max-height: 100px;
      overflow: auto;
      @include scrollbar();
    }
    .just-select-list {
      max-height: 240px;
      .scrollbar-container {
        max-height: 240px;
      }
    }
  }
}
.backDrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.LabelEmptyList {
  &_BotControls {
    cursor: default;
    display: flex;
    margin-top: 35%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .LabelEmptyList-title {
      text-align: center;
      color: var(--gray-600);
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }
  }
}
.LabelEmptyList_BotControls {
  cursor: default;
  margin: -12px;
  padding-top: 45%;
}
