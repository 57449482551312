.WizardStartCard {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--blue-200);
  width: 292px;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0), 0 2px 8px rgba(12, 43, 66, 0), 0 0 1px rgba(17, 60, 92, 0);
  transition: box-shadow ease-in-out 0.3s;

  &:hover {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
  }
}

.WizardStartImage {
  position: relative;
  width: 100%;
  min-height: 112px;
  height: 112px;
  background: var(--blue-100);
  display: flex;
  justify-content: center;
  align-items: center;
}

.WizardStartLabel {
  display: flex;
  padding: 2px 4px;
  border-radius: 4px;
  gap: 4px;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0075em;
  color: var(--white);

  > * {
    color: var(--white);
  }
}

.WizardStartCardBody {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 32px;
  height: 100%;
  background-color: var(--white);
}

.WizardStartCardBodyUl {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 24px;
  gap: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0075em;
  color: var(--gray-800);
  flex-grow: 1;
  .liItem {
    list-style: none;
    position: relative;
  }
  .liIcon {
    position: absolute;
    left: -24px;
  }
}
