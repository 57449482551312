@keyframes fallClose {
  from {
    transform: translate(0, 0);
    opacity: 0;
  }

  25% {
    transform: translate(0, 0);
    opacity: 1;
  }

  to {
    transform: translate(0, 20px);
  }
}

@keyframes hat {
  from {
    transform: translate(0);
  }

  25% {
    transform: rotate(35deg) translate(6px, -6px);
  }

  50% {
    transform: rotate(75deg) translate(2px, -25px);
  }

  75% {
    transform: rotate(90deg) translate(3px, -30px);
  }

  to {
    transform: rotate(90deg) translate(5px, -30.4px);
  }
}

.deletingIcon {
  &.finished {
    .hat {
      animation: hat 1s ease-in;
      animation-fill-mode: forwards;
      //animation-iteration-count: infinite;
    }
  }

  &.process {
    .circles {
      circle {
        opacity: 0;
        animation: fallClose 1.5s ease-in;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;

        &:nth-child(2) {
          animation-delay: 0.75s;
        }
      }
    }
  }
}
