.TextMessage {
  display: flex;
  flex-direction: column;
  color: var(--gray-800);
  gap: 8px;

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}
