.errorPanel {
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: var(--red-200);
  color: var(--red-600);

  &__title {
    font-size: 24px;
    font-weight: 600;
  }

  &__error {
    padding: 10px;
    border-radius: 4px;
    background: var(--red-100);
  }
}

.TemplatesWizard {
  width: 100%;
  min-width: 896px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 100px;

  &__skills {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: var(--blue-100);
  }

  &__bottomActions {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
    background-color: var(--blue-100);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
    border-radius: 12px;
    padding: 19px 24px;

    &__left {
      display: flex;
      gap: 16px;
    }

    &__right {
    }

    &__addSkillBtn {
      display: flex !important;
      align-items: center;
      gap: 8px;

      :global(.justui__icon-wrapper) {
        display: flex;
      }
    }
  }
}

.FieldsConfigBuilderView {
  display: grid;
  grid-template-columns: 60% 1fr;

  &__skills {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: var(--white);
    padding: 24px;
  }

  &__bottomActions {
    background-color: var(--blue-100);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
    border-radius: 12px;
    padding: 24px;
  }
}
