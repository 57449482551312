button.btn-round {
  height: 32px !important;
  width: 32px;
  border-radius: 50%;
  outline: none;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    background-color: var(--primary-100);
  }
}

.bot-menu-dropdown {
  .justui_dropdown-menu {
    .dropdown-item {
      padding: 8px 12px;
    }
  }
}

.TagsModalBody_wrapper {
  height: 430px;
  overflow: hidden;
  padding: 4px;
}
.TagsModalBody {
  min-height: 422px;
  margin: -4px auto;
  transform: translateX(0);
  .just-select-selected-item {
    background: var(--yellow-100);
    border-color: var(--yellow-300);
    color: var(--yellow-800);
    .just-select-selected-item-delete {
      fill: var(--yellow-500);
    }
  }
}
.TagsModalBody_container {
  padding: 4px 0;

  .just-select-list {
    box-shadow: none;
    max-height: 408px;
    .scrollbar-container {
      max-height: 408px;
    }
    .relativeWrapper {
      max-height: 408px;
    }
  }
  .just-select-item[data-test-id='just-select-item__new_value'] {
    padding: 0;
    .just-select-item__new_value_content {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      cursor: default;
      padding: 8px 12px;
      & > div:first-child {
        max-width: calc(100% - 100px);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        b:first-child {
          margin-left: 0.25rem;
        }
        b {
          line-height: 21px;
        }

        .just-select-item__new_value_value {
          max-width: 59%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
        }
      }
    }
  }

  .disabled {
    cursor: not-allowed;
    .just-select-item {
      cursor: not-allowed;
    }
  }
}

.LabelEmptyList {
  display: flex;
  padding-top: 105px;
  padding-bottom: 190px;
  margin-left: -12px;
  padding-left: 12px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: default;

  .LabelEmptyList-title {
    text-align: center;
    color: var(--gray-600);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  .LabelEmptyList-text {
    margin-top: 8px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: var(--gray-600);
  }
}

.Just-select-label-with-delete {
  display: flex;
  align-items: center;
  .Just-select-label-with-delete-firstLetter {
    width: 16px;
    line-height: 21px;
    margin-right: 8px;
    min-width: 16px;
    color: var(--gray-600);
    font-size: 14px;
  }
  .Just-select-label-text {
    flex-grow: 1;
    & + svg {
      line-height: 16px;
      cursor: pointer;
    }
  }
}
