.group {
  background-color: var(--gray-100);
  border-radius: 4px;
  position: relative;
  margin-bottom: 8px;
  padding: 24px 40px 24px 24px;
}

.groupDeleteBtn {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  z-index: 1;
  padding: 0;
}

.groupAddBtn {
  margin-bottom: 24px;
}

.groupFieldContainer {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.groupField {
  margin-right: 8px;
}

.destinationSelect {
  background-color: var(--white);
  z-index: auto;
  width: auto;
}
