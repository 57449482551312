.OperatorSkill {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--gray-800);

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .loadingBtn {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .successBtn {
    display: flex;
    align-items: center;
    gap: 6px;
    color: white;
    & svg {
      color: white !important;
    }
  }
  .linkBtn {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .typeRadioWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__hint {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--gray-600);
      padding-left: 24px;
    }
  }

  .jivoResult {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    width: 100%;
  }

  .integrationPanel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;
    background: var(--blue-100);
    border-radius: 8px;
  }

  .buttonContainer {
    display: flex;
    gap: 16px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--blue-200);
  }
}
