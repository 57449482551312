.wizardRouter {
  position: relative;
  margin: -24px -24px -4rem;
  width: calc(100% + 48px);
  padding: 0 16px;
  min-height: calc(100vh - 50px); //TODO calculate
  background: var(--blue-100);
  color: var(--gray-800);

  .header {
    display: grid;
    grid-template: 'back-button stepper close';
    justify-content: space-between;
    padding: 20px 16px 20px 0;
    color: var(--gray-600);

    .backButton {
      color: var(--gray-600) !important; //Anton Sergeew vs Katya Gordeeva
      grid-area: back-button;
    }
    .stepperContainer {
      grid-area: stepper;
    }
    .closeButton {
      color: var(--gray-600) !important; //Anton Sergeew vs Katya Gordeeva
      grid-area: close;
    }
  }
}
