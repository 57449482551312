.hiddenInput {
  position: absolute !important;
  top: -10000px !important;
  left: -10000px !important;
  visibility: visible;
}

.card {
  position: relative;
  height: 62px;
  margin-bottom: calc(var(--spacing-unit));
  border-radius: 0.5rem !important;

  display: grid;
  grid-template-columns: 20px 15% 15% 20% 10% auto;
  grid-template-rows: 62px;
  gap: 14px;

  grid-template-areas: 'icon botName script statusLabel whatNext buttons';

  padding: 0 18px;
  & > * {
    align-self: center;
  }
  @media (max-width: 770px) {
    height: 112px;
    grid-template-columns: 20px repeat(2, calc((100% - 90px) / 3)) 20px calc((100% - 90px) / 3);
    grid-template-rows: 56px 56px;
    gap: 0 10px;

    padding: 0 1rem;

    grid-template-areas:
      'icon botName script statusLabel'
      'whatNext whatNext buttons buttons buttons';
  }
}

.widgetLogoIcon {
  grid-area: icon;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.channelName {
  grid-area: botName;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 0.875rem;
}

.statusLabel {
  grid-area: statusLabel;
  font-size: 0.75rem !important;
}
.whatNext {
  grid-area: whatNext;
}

.buttons {
  grid-area: buttons;
  justify-self: end;
  white-space: nowrap;
}

.progress_button {
  height: 2rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
  font-family: Roboto, sans-serif;
  margin-right: 5rem;
  font-size: 12px;
  font-weight: 400;
  border: 0;
  background-color: var(--blue-600);
}

.disabledByWizardButton {
  & > * {
    // need for emulate disable button state without loosing mouse events
    pointer-events: none;
    svg {
      color: var(--gray-300);
    }
  }
}

.itemAction {
  padding: 7px;
  border-color: transparent;
  color: var(--var-800) !important;
  font-weight: 800;

  & svg {
    width: 0.875em;
    height: 0.875em;
  }

  &:hover {
    border-color: transparent;
    background-color: var(--primary-200);
  }

  &:focus {
    border-color: transparent;
    background-color: var(--primary-100);
  }

  &:disabled {
    border-color: transparent;
    background-color: transparent;

    & path {
      fill: var(--gray-100);
    }
  }
}

.itemActionIcon {
  width: 0.75em;
  height: 0.75em;
}

.getScriptBtn {
  color: var(--blue-600) !important;
  font-size: 0.875rem;
  cursor: pointer;
  text-decoration: none;
  width: auto;
  white-space: nowrap;

  &:hover {
    color: var(--blue-400) !important;
  }
}

.viewLogBtn {
  margin-left: calc(var(--spacing-unit) * 3 / 2);
}

.botLink {
  margin-left: 40px;
  font-size: 0.75rem;
}
