.ConfirmNumberModal {
  .modal .modal-title {
    display: inline-block;
    margin-left: 4px;
  }

  .modal__banner,
  .modal__info {
    margin-left: 4px;
  }

  .modal__info {
    margin-bottom: 10px;
  }

  .modal__banner {
    margin-bottom: 25px;
  }

  .modal__banner .justui_icon {
    align-self: flex-start;
    margin-top: 2px;
    margin-right: 8px;
  }

  .modal__phone,
  .modal__verification {
    margin: 0 0 0 4px;

    .invalid-feedback {
      display: block;
      position: relative;
    }
  }

  .modal__phone-wrapper {
    display: flex;
    align-items: center;
  }

  .modal__phone-wrapper .phone-container {
    width: 99%;
  }

  .modal__phone-info {
    width: 16px;
    height: 16px;
    margin-left: 10px;
  }

  .modal__verification {
  }

  .modal__check-number-info {
    margin-bottom: 4px;
    span {
      color: var(--primary);
      cursor: pointer;
    }
  }

  .modal__verification-inputs {
    display: grid;
    grid-template-columns: 180px auto;
    align-items: center;

    .digit-list {
      margin: 16px auto;

      input {
        border-radius: 8px;
      }
    }

    .invalid-feedback {
      display: block;
      margin: 0;
      padding-left: 11px;
      padding-right: 30px;
    }

    .resend-text {
      color: var(--primary);
      cursor: pointer;
    }
  }
  .modal__verification-inputs .digit-list {
    margin: 25px auto;
  }

  .modal__error {
    color: var(--danger);
    margin-top: 5px;
  }
  .modal__phone-wrapper input:-webkit-autofill,
  .modal__phone-wrapper input:-webkit-autofill:hover,
  .modal__phone-wrapper input:-webkit-autofill:focus,
  .modal__phone-wrapper input:-webkit-autofill:active {
    margin: 0;
  }

  .modal__captcha {
    opacity: 0;
    animation: captcha 1s forwards;
  }

  .resend-code-buttons {
    color: var(--primary);
    cursor: pointer;
  }
}

@keyframes captcha {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#widget-test-hidden {
  display: none;
}
