.form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 3px;
}

.vonageForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1rem;
  overflow: hidden;
  height: auto;
}

.vonageFormFooter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  overflow: hidden;
  height: auto;
}

.hidden {
  display: none !important;
}

.formControl {
  margin-bottom: 24px;
}

.container {
  display: flex;
  align-items: center;
}

.googleTriggerInfo {
  display: flex;
  margin-left: 8px;
  width: 16px;
  height: 16px;
}

.googleTrigger {
  margin-bottom: 8px;
}

.label {
  margin-bottom: 8px;
  color: var(--gray-800);
}

.labelRequired:after {
  content: ' *';
  color: var(--red);
}

.switchContainer {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
  }
}

.infoText {
  color: var(--gray-600);
}

.simpleMargin {
  margin-bottom: 12px;
}

.select {
  width: 100%;
  z-index: auto;
}

.operatorWebimBtn {
  margin-top: 8px;
}

.bitrixImgUploadContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
