@import '../../../helpers/styles/common.scss';

.botsList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;
}

.bot {
  @include whiteBoxWithShadow();
  height: 249px;
  width: 290px;

  padding: 1rem;

  display: grid;
  grid-template-columns: 70% auto;
  grid-template-rows: 32px minmax(21px, 42px) auto 18px 18px;
  gap: 8px;
  cursor: pointer;
  border: none;

  position: relative;
  transition: 100ms ease-in-out;
  will-change: background, width, height;

  &:hover {
    box-shadow: 0 0 1px 1px rgba(17, 60, 92, 0.06), 0 6px 12px 0 rgba(12, 43, 66, 0.16);
  }

  & > * {
    justify-self: start;
  }

  .channels {
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .additionalChannelButton {
    width: 24px;
    margin-left: 4px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid var(--gray-200);
    color: var(--gray-600);
    font-size: 0.75rem;
    border-radius: 50%;
    will-change: color, background-color;
    transition: 200ms ease-in-out;
    padding: 0 !important;
    height: 24px;
    width: 24px;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: var(--primary-400);
      color: var(--white);
    }
  }

  .languageAndControls {
    grid-column: 2/3;
    grid-row: 1/2;
    justify-self: end;
    align-self: center;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .flag {
      width: 20px;
      height: 16px;
      margin-right: 1rem;
      border: 1px solid var(--gray-200);
    }

    & > div,
    & > div > button {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .name {
    grid-column: 1/-1;

    div {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.02em;
      color: var(--primary);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      max-height: 100%;
      width: 100%;

      & > .nameString {
        width: 250px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0.25rem;
      }

      & > .shortName {
        font-size: 12px;
        font-weight: 400;
        color: var(--gray-600);
      }
    }
  }

  .popularity {
    grid-column: 1/2;
    grid-row: 4/5;

    .popularity_body {
      display: inline-flex;
    }

    .projectShortName {
      font-size: 12px;
      font-weight: 400;
      color: var(--gray-600);
      position: relative;

      &.withBorderLine {
        margin-right: 1rem;
        &:after {
          width: 1px;
          height: 100%;
          content: '';
          position: absolute;
          right: -8px;
          background-color: var(--gray-200);
        }
      }
    }
  }

  .tags {
    grid-column: 1/3;
    grid-row: 3/4;
    height: auto;
    position: absolute;
  }

  .date {
    grid-column: 1/2;
    grid-row: 5/5;
    font-weight: bold;
  }

  .date,
  .popularity {
    font-size: 12px !important;

    div,
    b {
      font-size: 12px !important;
    }

    svg {
      color: var(--gray-600);
      margin-right: 5px;
    }
  }
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;

  .tag {
    padding: 2px 7px;
    background-color: var(--yellow-100);
    border: 1px solid var(--yellow-200);
    border-radius: 3px;
    max-height: 24px;

    .tag_body {
      color: var(--yellow-800);
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.02em;
      white-space: nowrap;
      max-width: 15ch;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.Just-select-label-text {
  max-width: calc(100% - 16px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.TagsModalBody {
  .just-select-item {
    padding: 0 0 0 12px !important;

    .Just-select-label-with-delete {
      button {
        height: 37px !important;
        width: 40px !important;
      }
    }
  }

  .scrollbar-container {
    max-height: 350px !important;
  }
}

.botsList.list {
  gap: 8px;

  .bot {
    height: 88px;
    width: 100%;

    padding: 18px 24px;

    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 50% 20% auto 18px 32px;
    gap: 8px;
    cursor: pointer;
    border: none;

    &:hover {
      //transform: translate(-2px, 0);
      &:active {
        //transform: translate(-2px, 0);
      }
    }

    .channels {
      height: 100%;
      grid-column: 2/3;
      grid-row: 1/3;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .languageAndControls {
      grid-column: -2/-1;
      grid-row: 1/3;
    }

    .name {
      grid-column: 1/2;
      grid-row: 1/2;

      div {
        flex-direction: row;
      }
    }

    .tags {
      grid-column: 3/4;
      grid-row: 1/3;
    }

    .popularity-date {
      grid-column: 1/2;
      grid-row: 2/3;
      gap: 17px;
      display: flex;
      flex-direction: row;

      > div {
        display: inline-flex;
      }

      .date {
        font-weight: bold;
        position: relative;

        &:after {
          width: 1px;
          height: 100%;
          content: '';
          position: absolute;
          left: -8px;
          background-color: var(--gray-200);
        }
      }
    }

    .name {
      div {
        -webkit-line-clamp: 1;
      }
    }

    &:focus {
      outline: none;
    }
  }
}

.hiddenChannels {
  width: 7rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.channelIcon {
  margin-right: 2px;
  position: relative;

  &.channelInProgress:after,
  &.channelError:after,
  &.channelActive:after {
    content: ' ';
    position: absolute;
    height: 10px;
    width: 10px;
    top: 14px;
    border-radius: 50%;
    border: 2px solid var(--white);
    background-color: var(--gray-600);
    left: 0;
  }

  &.channelError:after {
    background-color: var(--danger);
  }

  &.channelActive:after {
    background-color: var(--success);
  }
}

.bot {
  &.trashContainer {
    overflow: hidden;
    will-change: max-width;
    top: 0 !important;
    left: 0 !important;
    filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.3));
    cursor: not-allowed;
    pointer-events: none;

    &,
    * {
      color: var(--primary);
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &.trashContainer.deleting {
    animation: disappear 0.3s ease-in;
    animation-fill-mode: forwards;

    svg,
    h3 {
      animation: trashDisappear 0.1s ease-in;
      animation-fill-mode: forwards;
    }
  }
}

.bot {
  &.listContainer {
    &.deleting {
      transform: translate(0, 0);
      filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0.3)) !important;
      cursor: not-allowed;
      pointer-events: none;
      animation: shrink 0.3s ease-in;
      animation-fill-mode: forwards;
    }

    & > div {
      grid-row: 1/-1;
      grid-column: 1/-1;
      height: 6px;
      width: 100%;

      &.pending {
        animation: shrinkFull 0.3s ease-in;
        animation-fill-mode: forwards;
      }
    }
  }
}

@keyframes disappear {
  from {
    max-width: 290px;
    opacity: 1;
  }
  to {
    max-width: 0;
    opacity: 0;
  }
}

@keyframes trashDisappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes shrink {
  0% {
    height: 80px;
  }
  100% {
    height: 14px;
    padding: 4px;
  }
}

@keyframes shrinkFull {
  0% {
    height: 6px;
  }
  100% {
    height: 0px;
  }
}
