.graphWayEndContainer {
  display: flex;
  position: relative;
  width: 604px;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;

  .finalCard {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--white);
    transition: box-shadow ease-in-out 0.3s;

    border: 1px solid var(--blue-200);
    border-radius: 12px;
    overflow: hidden;
    padding: 24px 32px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0), 0 0 0 rgba(0, 0, 0, 0);

    &:hover {
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
    }

    .details {
      margin: 20px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;
      border-top: 1px solid var(--blue-200);
      border-bottom: 1px solid var(--blue-200);
      padding: 20px 0;

      .detail {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }
  }
}
