.scrollbar {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #CCCCCC;
  }
}
