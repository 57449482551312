.ChatGPTBanner {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  align-content: flex-start;
  padding: 12px 16px;
  background: linear-gradient(90deg, #f0f8df 0%, #e0fae3 24.48%, #c7e0f7 46.88%, #ded4f9 72.4%, #f8c9f1 100%);
  border-radius: 8px;

  a {
    text-decoration: none;
  }

  &__icon {
    height: 20px;
    width: 24px;
    background: url('/img/just-emoji/brain.png') no-repeat center;
    background-size: contain;
  }

  &__text {
    white-space: break-spaces;
  }

  &.limitExceeded {
    background: var(--gray-200);
  }
}
