.StudioBannerContainer {
  width: 100%;
  height: 160px;
  margin: 1.5rem 0;
  background: linear-gradient(101.63deg, #e2ebff 0%, #f2f6ff 59.45%);
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  overflow: hidden;

  .backgrounds {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 160px;
    z-index: 0;
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .StudioBannerInfo {
    width: auto;
    height: 100%;
    position: relative;
    z-index: 1;
    padding: 32px 0 32px 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    &_text {
      width: 296px;
      white-space: pre-wrap;
      font-size: 14px;

      line-height: 18px;
      color: var(--gray-900);
      h3 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 0.5rem;
      }
      span {
      }
    }
    button {
      background-color: #7174e4;
      color: var(--white);
      white-space: nowrap;
      border-radius: 1rem;
      padding: 0 20px;
      font-size: 12px;
      text-decoration: none !important;
      &:hover,
      &:hover {
        text-decoration: none !important;
      }
    }
  }
}
