.justui_simpleSelect__container {
  width: auto;
  height: 40px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  border-radius: 3px;

};
.justui_simpleSelect__button {
  height: 100%;
  display: flex;
  padding: 0 0.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  z-index: 1;
  position: relative;
  cursor: pointer;

  &:nth-child(2) {
    border-radius: 3px 0 0 3px;
  }

  &:last-child {
    border-radius: 0 3px 3px 0;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem var(--primary-200);
    z-index: 2;
    outline: none;
  }
}

.justui_simpleSelect__primary {
  color: black;
  background: transparent;
  border: 1px solid #E4E5E6;
  transition: 300ms ease-in-out;
  &.active {
    color: var(--white);
    background: var(--primary);
    border: 1px solid var(--primary-700);
  }
}
