.MenuTable {
  display: flex;
  flex-direction: column;

  &__table {
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;
    table-layout: auto;

    & th {
      color: var(--gray-500);
    }
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-800);
  }

  &__header {
    display: contents;
    padding: 8px;
    background: var(--blue-100);
    border-radius: 8px;
    color: var(--gray-500);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }

  &__addBtn {
    align-self: flex-start;
    display: flex !important;
    flex-wrap: nowrap;
    align-items: center;
    gap: 6px;
  }

  :global(.dragging) {
    display: table;
    table-layout: fixed;
  }
}

.row {
  width: 100%;

  & th,
  & td {
    background: var(--blue-100);
    padding: 8px;
    vertical-align: top;

    &:nth-child(1) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      padding-left: 12px;
      padding-right: 0;
    }
    &:nth-last-child(1) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      padding-right: 12px;
      padding-left: 0;
    }
  }

  & .textInput {
    width: 200px;
    min-width: 200px;
  }
  & .actionColumn {
    width: 200px;
    min-width: 200px;
  }
  & .botActionColumn {
    width: 100%;
  }
  & .textArea {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .deleteBtn {
    border: none;
  }

  :global(.justui__icon-wrapper) {
    display: flex;
    width: 38px;
    height: 38px;
    align-items: center;
    justify-content: center;
  }
  :global(.just-select-container.disabled) {
    border: 1px solid var(--grey-200);
    & input {
      background: var(--blue-50);
    }
  }
}

.drugHelper {
  vertical-align: middle;
  opacity: 0.9;
}
