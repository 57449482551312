.WizardStartContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0 0;
  gap: 2rem;
  .WizardStart {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    gap: 20px;
  }

  .WizardLabelContainer {
    position: absolute;
    top: 16px;
    right: 16px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }
}
