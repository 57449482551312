.AutomessageSequenceSkillContainer{
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;

  &_footer{
    border-top: 1px solid var(--blue-200);
    padding-top: 20px;
  }
}

.AutomessageSequenceSortableContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
}
