.ConnectChannels {
  padding: 32px 8px;

  &__card {
    background: var(--white);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
    border-radius: 12px;
    padding-top: 24px;
    margin: 0 auto;
    max-width: 1280px;

    & :global(.channels-page-wrapper) {
      max-width: 100%;
      width: 100%;
    }
  }

  .bottomActions {
    display: flex;
    gap: 16px;
    padding: 0 0 24px 24px;
  }
}
