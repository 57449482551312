@use "sass:math";

@import '~flag-icon-css/css/flag-icon.css';

@import '~@just-ai/just-ui/scss/basic/build.main';
@import '~@just-ai/just-ui/scss/basic/jaicp/colors';

button:focus {
  outline: none;
}

a:not([href]):not([tabindex]) {
  color: var(--primary-400);

  &:focus,
  &:hover {
    color: var(--primary);
  }
}

b,
strong {
  margin-bottom: 0;
}

#attention.fulfilled {
  ~ div > div > .modal .modal-dialog {
    margin-top: 6rem;
  }
}

.flag-icon-pt {
  background-image: url(../../../node_modules/flag-icon-css/flags/4x3/br.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(../../../node_modules/flag-icon-css/flags/1x1/br.svg);
}

:root {
  --shadows-1: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12) px;
  --shadows-2: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12) px;
  --shadows-3: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 3px 3px -2px rgba(0, 0, 0, 0.12) px;
  --shadows-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12) px;
  --shadows-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12) px;
  --shadows-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12) px;
  --shadows-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14),
    0px 2px 16px 1px rgba(0, 0, 0, 0.12) px;
  --shadows-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12) px;
  --shadows-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14),
    0px 3px 16px 2px rgba(0, 0, 0, 0.12) px;
  --shadows-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14),
    0px 4px 18px 3px rgba(0, 0, 0, 0.12) px;
  --shadows-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14),
    0px 4px 20px 3px rgba(0, 0, 0, 0.12) px;
  --shadows-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 5px 22px 4px rgba(0, 0, 0, 0.12) px;
  --shadows-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14),
    0px 5px 24px 4px rgba(0, 0, 0, 0.12) px;
  --shadows-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14),
    0px 5px 26px 4px rgba(0, 0, 0, 0.12) px;
  --shadows-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14),
    0px 6px 28px 5px rgba(0, 0, 0, 0.12) px;
  --shadows-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12) px;
  --shadows-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14),
    0px 6px 32px 5px rgba(0, 0, 0, 0.12) px;
  --shadows-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14),
    0px 7px 34px 6px rgba(0, 0, 0, 0.12) px;
  --shadows-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14),
    0px 7px 36px 6px rgba(0, 0, 0, 0.12) px;
  --shadows-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14),
    0px 8px 38px 7px rgba(0, 0, 0, 0.12) px;
  --shadows-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14),
    0px 8px 40px 7px rgba(0, 0, 0, 0.12) px;
  --shadows-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14),
    0px 8px 42px 7px rgba(0, 0, 0, 0.12) px;
  --shadows-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14),
    0px 9px 44px 8px rgba(0, 0, 0, 0.12) px;
  --shadows-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12) px;
  --shape-borderRadius: 4px;
  --spacing-unit: 8px;
}

.just-ui-custom-scrollbar {
  &::-webkit-scrollbar {
    position: relative;
    width: 16px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 10px;
    background-clip: padding-box;
    border-right: 5px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 5px solid transparent;
    border-radius: 6px;
    background-color: var(--gray-200);
    cursor: pointer;
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 0;
  }
}

.margin-bottom-3x{
  margin-bottom: 12px;
}

.pseudoLink {
  font-size: #{math.div(14, 16)}rem;
  line-height: #{math.div(21, 16)}rem;
  letter-spacing: 0.02em;
  padding: 2px 0;
  color: var(--primary);
  border-bottom: 1px dashed var(--primary);
  cursor: pointer;

  &:hover {
    border-bottom-color: transparent;
  }
}

.border-bottom-1px-blue-200{
  border-bottom: 1px solid var(--blue-200);
}

.links-disabled {
  display: none;
}

.preventPointerEvents {
  & > * {
    // need for emulate disable button state without loosing mouse events
    pointer-events: none;
  }
}
