.GoogleTablesBlock {
  &__integration_card {
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: 8px 8px 8px 16px;

    border: 1px solid var(--gray-200);
    border-radius: 8px;
    width: 100%;

    &__block {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
    }
  }

  .googleButton {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 6px;
    position: relative;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .cardInfoBlock {
    position: relative;
    justify-self: center;
  }
}

.settingsModalContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--gray-800);

  & .dropdownWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    :global(.just-select-container) {
      border-radius: 8px;
      input {
        color: var(--gray-800);
        border-radius: 8px;
      }
    }
  }
}
