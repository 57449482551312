@import "~@just-ai/just-ui/scss/basic/custom/functions";
@import "~@just-ai/just-ui/scss/basic/custom/variables";

.justui_img-upload{
  width: 144px;
  height: 144px;
  padding: 8px;
  position: relative;

  &-container{
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid #E7EBED;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    position: relative;
    z-index: 2;

    &:hover{
      background-color: lighten($primary, 20%);

      svg{
        color: $white !important;
      }
    }

    &.has-img:not(.uploading){
      svg{
        display: none;
      }
      &:hover{
        background-color: transparent;

        svg{
          display: block;
          position: relative;
          z-index: 1;
        }
      }
      &:hover::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    label{
      width: 100%;
      height: 100%;
      display: flex;
      margin: 0;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .hidden{
    display: none;
  }

  #justui_circular-progress {
    display: block;
    border-radius: 100%;
    height: 144px;
    width: 144px;
    position: absolute;
    top: 0;
    left: 0;

    circle{
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 1s linear;
      stroke-width: 8px;
      stroke: $green;
      transform: rotate(-90deg);
      transform-origin: center center;
    }
  }
}
