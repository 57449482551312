.telegram_edit_form {
  .justui_collapse__container {
    padding: 0;
    border: 0;

    .justui_collapse__header-opened {
      margin-bottom: 1rem;
    }

    .justui_collapse__header {
      justify-content: start;

    & > span {
        padding-right: .5rem;
        color: var(--blue-600);
      }

      button:hover,
      button:focus,
      button:active
      {
        background: transparent;
      }
    }
  }

  a {
    color: var(--blue-600);
    text-decoration: none !important;
  }
}
