.container {
  margin-bottom: 1.5rem;

  display: grid;
  grid-template-columns: 117px 267px auto 288px 64px;
  grid-template-rows: 38px;
  gap: 25px;
  z-index: 1;
  .createButton {
    padding: 0;
    grid-column: 1/2;
  }
  .searchInput {
    grid-column: 2/3;
    input {
      border-radius: 0.25rem !important;
    }
  }
  .sortType {
    grid-column: 4/5;
  }
  .tagsFilter {
    grid-column: 3/4;
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .selectOrder {
    grid-column: 4/5;
  }

  .displayControl {
    grid-column: 5/-1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    button {
      padding: 0px !important;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        cursor: default;
        background-color: var(--gray-200);
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}
