.container {
  display: grid;
  height: auto;
  width: 100vw;
  grid-template-columns: 60px auto auto;
  grid-template-rows: minmax(48px, auto);
  grid-template-areas: 'logo left right';
  background-color: var(--white);
  position: relative;
  border-bottom: 1px solid var(--gray-100);
  z-index: 100;
  grid-area: header;

  &.mobileContainer {
    grid-template-columns: 56px calc(100vw - 104px) 48px;
  }

  .gamburber {
    * {
      fill: var(--grey-950);
    }
  }
  * {
    font-family: 'Roboto', sans-serif !important;
  }
}
.logo {
  width: 60px;
  height: 48px;
  grid-area: logo;
  display: grid;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--gray-100);

  svg {
    width: 36px;
    height: 32px;
  }
}

.leftList {
  grid-area: left;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  .leftListItem {
    height: 49px;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-shadow: 0px 1px 0 0 var(--gray-100), 0px -1px 0 0 var(--gray-100), -1px 0px 0 0 var(--gray-100);
    border-right: 1px solid var(--gray-100);
    background-color: var(--white);

    color: var(--gray-950);
    font-size: 0.75rem;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 0.09px;

    will-change: background-color, border-bottom;
    transition: 220ms 100ms ease-in-out;

    & > svg {
      height: 18px;
      width: auto;

      path {
        fill: var(--gray-950);
      }
    }

    svg.greyIcon {
      height: 8px;
      width: 8px;

      path {
        fill: var(--gray-300);
      }
    }

    svg.right {
      margin-left: 8px;
    }

    svg.left {
      margin-right: 8px;
    }

    &.active {
      transition: 220ms ease-in-out;
      box-shadow: 0 1px 0 0 var(--gray-100);
      background-color: var(--gray-100);
      pointer-events: none;
    }
  }
}

.rightList {
  grid-area: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .orderBotButton {
    border: 1px solid var(--primary-600) !important;
    box-sizing: border-box !important;
    border-radius: 3px !important;
    height: 30px;

    span {
      font-size: 12px;
      font-weight: 400;

      line-height: 20px;
      text-transform: initial;
      color: var(--primary);
    }
  }
}

.paymentContainer,
.personalContainer {
  position: relative;
  height: 100%;

  &:hover .popup {
    opacity: 1;
    transform: translate(-50%, 1px);
    animation: none;
    pointer-events: initial;
  }
}

.mobilePaymentContainer {
  border-left: 1px solid var(--gray-100);
}

.tariffPopup,
.personalPopup {
  padding: 1rem 0;
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  & > span,
  *:not(button) span {
    margin-bottom: 8px;
    color: var(--gray-600);
    font-weight: 600;
    font-size: 0.75rem;
  }

  & > *:not(button) {
    padding: 0 16px;
    display: block;
  }

  .tariffPrice {
    background-color: var(--primary-100);
    padding: 0;
    margin-left: 16px;
  }

  .delimiter {
    margin: 8px 0 16px 0;
    width: 100%;
    height: 1px;
    background-color: var(--gray-100);
  }

  .button {
    align-self: center;
  }
}

.paymentText {
  width: 220px;
  height: 100%;

  border: none;
  background-color: var(--white);

  color: var(--gray-600);
  font-size: 12px;
  font-weight: 600;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:active,
  &:focus {
    outline: none;
  }
}

.freeTariff {
  width: 280px;
}

.mobilePopup {
  width: 100% !important;

  & > div {
    width: 100% !important;
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & > span,
    *:not(button) span {
      margin-bottom: 8px;
      color: var(--gray-600);
      font-weight: 600;
      font-size: 0.75rem;
    }

    button span {
      color: var(--white);
      margin-bottom: 0px;
      font-weight: normal;
    }

    & > span {
      margin-left: 1rem;
    }
  }
}

.mobilePaymentText {
  width: 100%;
  font-size: 14px;
}

.personalContainer {
  position: relative;
  height: 100%;
  width: 54px;
  border-left: 1px solid var(--gray-100);

  display: flex;
  justify-content: center;
  align-items: center;

  &.mobilePersonalContainer {
    width: 48px;
  }

  .popup {
    width: 190px;
    left: initial !important;
    right: 0 !important;
    transform: translate(0%, -5px) !important;
  }

  &:hover .popup {
    opacity: 1;
    transform: translate(0%, 1px) !important;
    pointer-events: initial;
    animation: none;
  }

  & > button {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:active {
      outline: none;
    }
  }
}

.avatarContainer {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: var(--gray-200);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar {
    height: 32px;
    width: auto;
    border-radius: 50%;
  }

  svg {
    height: 24px !important;
    width: 24px !important;

    path {
      fill: var(--pink);
    }
  }
}

.personalPopup {
  width: initial !important;
  min-width: 190px !important;
  max-width: 400px;
  padding: 8px 0;

  * {
    color: var(--gray-950) !important;
  }

  .delimiter {
    margin: 8px 0;
  }

  .link {
    margin: 12px 0;

    width: 100%;

    padding: 0 16px;

    border: none;
    background-color: var(--white);

    text-align: left;
    white-space: nowrap;
    text-decoration: none;
    font-size: 0.75rem;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.threeDotsContainer {
  position: relative;

  .threeDots {
    height: 8px !important;
    cursor: pointer;
  }

  &:hover .popup {
    opacity: 1;
    transform: translate(-50%, 1px);
    pointer-events: initial;
    animation: none;
  }

  .additionalInfo {
    width: 320px;
    height: auto;
    display: grid;
    grid-template-columns: 320px;
    grid-template-rows: auto;
    overflow: hidden;

    .links {
      padding: 26px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;

      * {
        text-decoration: none;
        color: var(--primary);
        letter-spacing: 0.3px;
        white-space: nowrap;
      }

      .label {
        width: 100%;

        display: grid;
        grid-template-columns: 25px calc(100% - 20px);
        gap: 10px;

        font-weight: 600;

        svg {
          justify-self: end;
          height: 25px;
          width: 25px;
        }

        * {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .link {
        width: 100%;
        margin-left: 34px;
        font-weight: 400;
      }
    }

    &.withTeaser {
      width: 640px;
      height: 410px;
      max-width: 100vw;
      grid-template-columns: 320px 320px;

      .teaser {
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;

        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}

.popup {
  min-width: 190px;
  min-height: 110px;

  position: absolute;
  top: 100%;
  left: 50%;

  border-radius: 0px 0px 8px 8px;

  background: var(--white);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(12, 43, 66, 0.16), 0px 0px 1px rgba(17, 60, 92, 0.06);

  opacity: 0;
  transform: translate(-50%, -10px);
  will-change: opacity, transform, pointer-events;
  pointer-events: none;
  transition: opacity 100ms 400ms, transform 300ms ease-in-out 200ms;

  animation-fill-mode: forwards;
  animation: pointerEventAnimation;
  animation-duration: 500ms;

  z-index: 2;

  &.blocked {
    pointer-events: none !important;
  }
}

@keyframes pointerEventAnimation {
  0% {
    pointer-events: initial;
  }
  99% {
    pointer-events: initial;
  }
  100% {
    pointer-events: none;
  }
}

.jaicpIcon {
  width: 18px !important;
  height: 20px !important;
}

.blackText {
  color: var(--gray-950) !important;
}

.slimText {
  font-weight: 400 !important;
}

.boldText {
  font-weight: 600 !important;
}

.email {
  max-width: 100%;
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  .copyButton {
    margin-left: 0.5rem;
    height: 1.75rem;
    width: 1.75rem;

    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: 300ms ease-in-out;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: var(--gray-100);
    }
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  & > span {
    margin-bottom: 0 !important;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.info {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
