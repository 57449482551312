.graphWayContainer {
  width: 604px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  .mb-3_5 {
    margin-bottom: 0.75rem;
  }
}

.graphWayTemplatesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
