.WizardSteps {
  display: grid;
  grid-template-columns: 896px 288px;
  align-items: flex-start;
  gap: 24px;
  width: calc(288px + 896px + 24px);
  margin: 32px auto 0;
}

.WizardStepsContent {
  display: flex;
  flex-grow: 1;
  max-width: 896px;
}

.WizardStepsPseudoWidget {
  height: 100%;
  display: block;
  max-width: 288px;
}
