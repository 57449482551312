.SkillCollapseContainer {
  background-color: var(--blue-100);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
  border-radius: 12px;
  padding: 24px;
  transition: background-color 300ms ease-in-out;

  &__content {
  }

  &__header {
    &__name {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &.isPostSetup {
    .SkillCollapseContainer__header__name h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .icon {
    transition: transform 300ms ease-in-out;
    transform: rotate(0);
  }

  &_opened {
    background-color: var(--white);

    & .icon {
      transform: rotate(90deg);
    }
  }

  .bottomButtons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    &__buttonsGroup {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__buttonWithIcon {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
