.container {
  --height: 3.5rem;

  position: relative;

  height: var(--height);
  width: 100%;

  padding: 1.125rem;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 1;

  * {
    outline: none;
    border: none;
    padding: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    color: var(--gray-950);
  }

  border: 1px solid var(--gray-200);

  box-sizing: border-box;
  border-radius: 3px;

  &:focus-within {
    border: 1px solid var(--yellow-300);
  }

  &.error {
    border-color: var(--pink);
  }
}

.setCodeContainer {
  .toggleButton {
    transform: rotateZ(0);
    will-change: transform;
    transition: 200ms ease-in-out;
    cursor: pointer;
    &.on {
      transform: rotateZ(180deg);
    }
  }

  .countryListContainer {
    position: absolute;
    top: var(--height);
    left: 0;
    right: 0;

    max-height: 0;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    will-change: max-height;
    transition: max-height 200ms ease-in-out, box-shadow 0ms;

    background-color: white;
    box-shadow: none;
    &.visible {
      max-height: 300px;
      box-shadow: 0px 0px 1px rgba(17, 60, 92, 0.06), 0px 6px 12px rgba(12, 43, 66, 0.16),
        0px 0px 2px rgba(0, 0, 0, 0.1);
    }
    .countryShadowWrapper {
      width: 100%;
      height: auto;
      .countryList {
        width: calc(100% - 5px);
        max-height: calc(300px - 2.5rem);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;

        overflow: auto;
        height: auto;

        .country {
          padding-right: 1.5rem;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          & > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }

          & > *:last-child {
            color: var(--gray-600);
          }
        }

        .flagWrapper {
          margin: 0.25rem 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          height: 2rem;
          width: 2rem;
          border-radius: 5rem;

          & > div {
            height: 3rem;
            width: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .flag {
            height: 3rem;
            width: 3rem;
            line-height: 2.5rem;
          }
        }

        &::-webkit-scrollbar {
          min-width: 6px;
          width: 6px;
          padding-right: 2px;
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          min-height: 60px;
          height: 60px;
          border-radius: 100px;
          background-color: var(--grey-500);
          opacity: 0.5;
          border: none;
        }

        &::-webkit-scrollbar-button {
          display: block;
          height: 0;
        }
      }
    }
    .searchInput {
      height: 2.5rem;

      padding: 0.625rem 0.875rem 0.625rem 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid var(--gray-200);
    }
  }
}
.setCode {
  padding-right: 1rem;
  width: 3rem;
}
.setNumber {
  padding-left: 1.5rem;
}

.setCode,
.setNumber {
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}
