@mixin customScrollbar() {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: var(--background-lightest);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-300);
    border-radius: 5px;
    &:hover {
      background-color: var(--gray-500);
    }
  }
}
