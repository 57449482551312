.WizardCard {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border: 1px solid var(--blue-200);
  border-radius: 12px;
  min-height: 194px;
  padding: 16px 20px;
  cursor: pointer;
  position: relative;

  transition: background 300ms ease-in-out;

  &_Title {
    min-height: 48px;
    padding-right: 24px;
    overflow-wrap: break-word;
  }

  &_Icon {
    position: absolute;
    top: 16px;
    right: 20px;
    width: 20px;
    height: 20px;
    > svg {
      width: 20px !important;
      height: 20px !important;
    }
  }

  &_Disabled &_Icon svg {
    color: var(--gray-500) !important;
  }

  &_Selected {
    border-color: var(--blue-300);
    box-shadow: inset 0 1px 0 0 var(--blue-300), inset 0 -1px 0 0 var(--blue-300), inset 1px 0 0 0 var(--blue-300),
      inset -1px 0 0 0 var(--blue-300);
  }
  &_Disabled {
    background: var(--gray-100);
    color: var(--gray-500);
    cursor: auto;
  }

  &_Button {
    align-self: flex-start;
  }

  &_hint {
    color: var(--gray-500);
    font-size: 12px;
    padding-right: 50px;
  }

  &_Description {
    flex-grow: 1;
    margin-bottom: 12px;
  }

  &__tooltip {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    width: 310px;

    .tooltipText {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.0075em;
    }

    & :global(.justui_button) {
      white-space: nowrap;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: var(--gray-950);
    }
  }
}

.chatGPTTooltip :global(.tooltip) {
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0.0075em;

  a {
    text-decoration: none;
  }
}

.specialMark {
  position: absolute;
  top: -10px;
  left: 16px;
  padding: 2px 4px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.01em;

  background-color: var(--yellow-200);
  color: var(--yellow-800);
  border-radius: 2px;
}

.chatGPTMarkerWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(var(--size) * 1px);
  height: calc(var(--size) * 1px);
  overflow: hidden;

  .chatGPTMarker {
    position: absolute;
    bottom: -4px;
    right: -4px;
    height: 100%;
    width: 100%;

    border-radius: 50%;
    background: linear-gradient(90deg, #f0f8df 0%, #e0fae3 24.48%, #c7e0f7 46.88%, #ded4f9 72.4%, #f8c9f1 100%);

    &.isChatGPTLimitExhausted {
      background: var(--gray-200);
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      background: url('/img/just-emoji/brain.png') no-repeat center;
      background-size: calc(var(--size) / 2 * 1px - 3px);
      line-height: 16px;
      content: '';
    }
  }
}
