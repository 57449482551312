.flexCenter {
  display: initial;
}

.collapseRoot {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.headerBotSelector {
  cursor: pointer;
  padding: 8px 40px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  &:hover {
    background-color: rgba(217, 217, 217, 0.2);
  }
}

.newBotButton {
  font-size: 12px;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--primary);
  font-weight: 600;
  gap: 6px;
  &_plusIcon {
    display: inline-flex;
    color: var(--primary);
    border: solid 1px var(--primary);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    line-height: 20px;
    align-items: center;
    justify-content: center;
  }
}

.newBotButtonCollapse {
  font-size: 12px;
}

.menuToggler {
  border: none;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  padding: 7px 20px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
  max-width: 100%;
  display: block;
  & > span > svg {
    margin-bottom: -3px;
  }
}
