.NewTemplateCard{
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  background: var(--white);
  border-radius: 12px;
  border: 1px solid var(--blue-200);
  overflow: hidden;
  color: var(--gray-800);
  cursor: pointer;
  transition: box-shadow ease-in-out 0.3s;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0), 0 2px 8px rgba(12, 43, 66, 0), 0 0 1px rgba(17, 60, 92, 0);

  &:hover{
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(12, 43, 66, 0.12), 0 0 1px rgba(17, 60, 92, 0.06);
  }

  svg{
    color: var(--blue-400); //DESIGN kostyl
  }

  &_fixedHeight{
    min-height: 48px;
  }

  &_vertical{
    padding: 20px 24px;
    gap: 20px;
    .NewTemplateCard_fixedHeight{
      min-height: auto;
    }
  }
}
