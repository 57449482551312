.tooltipCustom {
  background-color: var(--white) !important;
  max-width: 274px !important;
  color: var(--gray-800) !important;
  padding: 12px 16px !important;
}

.dropShadow {
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0px 6px 12px rgba(12, 43, 66, 0.16))
    drop-shadow(0px 0px 1px rgba(17, 60, 92, 0.06));

  &[x-placement|='top'] :global(.arrow::before) {
    border-top-color: var(--white) !important;
  }
  &[x-placement|='left'] :global(.arrow::before) {
    border-left-color: var(--white) !important;
  }
  &[x-placement|='right'] :global(.arrow::before) {
    border-right-color: var(--white) !important;
  }
  &[x-placement|='bottom'] :global(.arrow::before) {
    border-bottom-color: var(--white) !important;
  }
}
