.container {
}

.iconContainer {
  .icon {
    width: 46px;
  }
}

.info {
  padding-left: 19px;
  margin-right: 1rem;
  flex-grow: 1;
}

.popoverContainer {
  margin: 0 1rem;
  padding: 0;
}

.whiteIcon {
  fill: white !important;
}

.modal {
  padding: 0 !important;
  width: auto !important;
  margin: 40px 0 0 0 !important;
  max-width: calc(100vw - 2rem) !important;
}

.modalIconClose {
  top: 13px !important;
  right: 13px !important;
  width: auto !important;
  height: auto !important;
  z-index: 2;
}

.modalContent {
  max-height: calc(100vh - 4rem) !important;
  & > div {
    padding: 0 !important;
    max-height: calc(100vh - 5rem) !important;
  }
  &.tariffInfoContainer {
    overflow: hidden;
    & > div {
      padding: 1rem !important;
    }
  }
  .chooseTariffButton {
    display: block;
    margin-top: 1rem;
    text-decoration: none;
    text-align: center;
  }
  &.modalContentWithButton {
    padding-bottom: 72px !important;
    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    button {
      margin-top: 40px;
    }
  }
}

.tariffs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  & > span,
  *:not(button) span {
    margin-bottom: 8px;
    color: var(--gray-600);
    font-weight: 600;
    font-size: 0.75rem;
  }
  & > *:not(button) {
    padding: 0 16px;
    display: block;
  }
  .tariffPrice {
    background-color: var(--primary-100);
    padding: 4px;
    margin-left: 16px;
  }
  .delimiter {
    margin: 8px 0 16px 0;
    width: 100%;
    height: 1px;
    background-color: var(--gray-100);
  }
  .button {
    align-self: center;
  }
}
.linksList {
  padding-top: 52px !important;
  width: 100%;
  overflow: hidden;

  @media (max-height: 500px) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &.withoutPadding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.linkContainer {
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center;
  padding: 0 !important;
  border-bottom: 1px solid var(--gray-200) !important;
  a {
    padding: 11px 1rem !important;
    height: 100%;
    width: 100%;
  }
  .link {
    text-decoration: none;
    font-weight: 600;
    text-align: center;
    line-height: 2;
  }
}

.linkWithIcons {
  height: 48px;
  justify-content: flex-start !important;
  .link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
}
