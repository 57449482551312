@import '../../helpers/styles/customScrollbar';
.container {
  margin: 0;
  height: 100%;
  width: 100%;

  padding: 8px 0;

  overflow-y: auto;
  overflow-x: hidden;
  @include customScrollbar();

  display: grid;
  gap: 28px 0px;
  grid-template-columns: auto minmax(0, 1280px) auto;
  grid-template-rows: min-content min-content;
  & > * {
    grid-column: 2/3;
  }
  @media (max-width: 1296px) {
    padding: 8px;
  }

  * {
    font-family: 'Roboto', sans-serif !important;
  }
}

.bots {
  width: 100%;
}
.noSolutionContainer {
  padding-top: 20px;
  height: 160px;
  background-color: var(--white);
  grid-column: 1/-1;
  grid-template-columns: auto minmax(0, 1280px) auto;
  display: grid;
  div {
    grid-column: 2/3;
    padding: 20px;
    h3 {
      font-size: 2rem;
      margin-top: 8px;
      margin-bottom: 20px;
      line-height: 40px;
      font-weight: 300;
    }
    span {
      letter-spacing: 0.105px;
      margin-bottom: 12px;
      margin-top: 12px;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
