.modal {
  max-width: calc(100vw - 2rem) !important;
  margin: 1rem !important;
  padding: 1.5rem !important;
}

.modalHeadlineIcon {
  top: -37px !important;
  right: -37px !important;
}

.bottons {
  @media (max-width: 450px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
  }
}
