.FieldsCollections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  & .addBtn {
    margin-top: 8px;
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 6px;

    :global(.justui__icon-wrapper) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    & .row {
      width: 100%;
      padding: 16px 8px 16px 12px;
      background: var(--blue-100);
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 8px;

      &__fields {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
      }

      .deleteBtn {
        border: none;
      }

      .dropDown {
        width: 100%;
      }

      :global(.justui__icon-wrapper) {
        display: flex;
        width: 38px;
        height: 38px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
