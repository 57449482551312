@mixin scrollbar() {
  /*scrollbar active*/

  &::-webkit-scrollbar {
    min-width: 6px;
    width: 6px;
    padding-right: 2px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: var(--gray-500);
    opacity: 0.5;
    border: none;
  }

  &::-webkit-scrollbar-button {
    display: block;
    height: 0;

  }
}
