.facebookButton {
  svg {
    margin-left: 8px;
  }
}

.facebookModal {
  [role='document'] {
    width: 516px;
  }
}
