.MessageContainer{
  display: flex;
  flex-grow: 1;
  background-color: var(--blue-100);
  border-radius: 8px;
  padding: 12px 8px;
  color: var(--gray-800);
  flex-direction: column;
  gap: 12px;

  &_radio{

    label + label{
      margin-top: 8px;
    }

    &_timePicker{
      margin-top: 8px;
      margin-left: 22px;
      display: flex;
      flex-direction: row;
      gap:4px;

      &_timeValue{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;

      }
      &_InputContainer{
        position: relative;
        width: 56px;
      }
    }
  }
  &_content{
    display: flex;
    flex-direction: column;
    gap: 12px;

    [class~='faq-answer-container']{
      border-radius: 8px;
      background-color: var(--white);
      border: 1px solid var(--grey-300);
      min-height: 65px;
      padding-bottom: 24px;

      [class~='answers-textarea']{
        border: none;
        height: auto;
        display: grid;
        line-height: 20px;
        background-color: transparent;
        padding-left: 4px;
      }
      [class='faq-answer__part-content']{
        padding: 4px 0;
      }
    }
  }
}

[class~='InlineButtons-full-width'] {
  [class~='btn-group'] {
    display: flex;
    flex-grow: 1;
    button{
      flex-basis: 50%;
    }
  }
}
