.WizardSteps{
  display: flex;
  margin: 0 auto;
  width: 670px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .WizardStep{
    position: relative;
    padding: 6px 18px 6px 42px;
    border: 2px solid var(--blue-400);
    border-radius: 31px;
    background: transparent;
    overflow: hidden;

    color: var(--blue-400);

    [class='justui__icon-wrapper']{
      width: 16px;
      height: 16px;
      position: absolute;
      left: 20px;
      top: 7px;
      /*top: 50%;
      transform: translateY(-50%);*/ /*WHY ITS NOT WORKING???*/
    }
  }

  .WizardStepActive{
    border-color: var(--blue-500);
    color: var(--blue-600);
    [class='justui__icon-wrapper']{

    }

  }
  .WizardStepFilled{
    border-color: var(--blue-200);
    background: var(--blue-200);
  }

  .WizardStepDash{
    display: flex;
    flex-grow: 1;
    height: 0;
    border: 1px dashed var(--blue-400);
  }

}
