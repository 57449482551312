.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > *:first-child {
    svg {
      width: 40vw;
      height: 40vh;
    }
  }
}
